<template>
  <div>
    <guest-navbar />
    <guest-slider>
      <template slot="content">
        <div class="mb-4" v-if="is_valid && !password_reset">
          <h5>Recover your account</h5>
          <hr />
          <form @submit.prevent="submit">
            <div class="form-group">
              <label>Set your new password</label>
              <input
                type="password"
                v-model="account.password"
                placeholder="Password"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label>Confirm your new password</label>
              <input
                type="password"
                v-model="account.password_confirmation"
                placeholder="Confirm Password"
                class="form-control"
              />
            </div>
            <div class="form-group text-right">
              <button class="btn btn-secondary">Proceed</button>
            </div>
          </form>
        </div>
        <div class="mb-4" v-if="is_valid && password_reset">
          <h5>Recover your account</h5>
          <hr />
          <div class="alert alert-success">
            <h4>Success</h4>
            <p>
              Your password has been reset. You may now log in to your account
            </p>
          </div>
          <div class="text-center">
            <router-link :to="{ name: 'login' }" class="btn btn-secondary mt-3"
              >Login</router-link
            >
          </div>
        </div>
        <div class="mb-4" v-if="!is_valid">
          <div class="alert alert-danger">
            <h4>Failed</h4>
            <p>The password recovery token is invalid.</p>
          </div>
        </div>
      </template>
    </guest-slider>
    <guest-footer />
    <guest-quote />
  </div>
</template>

<script>
export default {
  mounted() {
    this.verify();
  },

  data() {
    return {
      is_valid: true,
      account: {},
      password_reset: false,
    };
  },

  methods: {
    submit() {
      let data = this.account;
      data.token = this.$route.params.token;
      this.$loader.start();

      this.$axios.post("/api/v1/recover-account", data).then((response) => {
        let status = response.data;
        this.$loader.stop();

        if (status == 0) {
          this.is_valid = false;
        } else {
          this.password_reset = true;
        }
      });
    },

    verify() {
      this.$loader.start();
      this.$axios
        .post("/api/v1/verify-account", { token: this.$route.params.token })
        .then((response) => {
          this.$loader.stop();
          this.is_valid = response.data;
        });
    },
  },
};
</script>
